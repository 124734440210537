<script>
import { icons } from './data-fontawesome'

export default {
    props: [
        'modelValue',
        'state',
        'error',
        'placeholder'
    ],

    computed: {
        model: {
            get: function () {
                return this.modelValue
            },
            set: function (value) {
                this.$emit('update:modelValue', value)
            },
        },
    },

    data: function () {
        return {
            icons: icons,
            show: false
        };
    },

    methods: {
        iconClass: function (icon, type) {
            let iconClass = `fa-${icon} `;

            switch (type) {
                case 'brands':
                    iconClass += 'fab';
                    break;
                case 'solid':
                    iconClass += 'fas';
                    break;
                default:
                    iconClass += 'far';
                    break;
            }

            return iconClass;
        },

        select: function (icon) {
            this.model = icon;
        },
    }
}

</script>

<template>
    <label class="cursor-pointer m-0 p-custom" for="icon-selector" @click="show = true"><i class="fas fa-folder-open"></i></label>
    <input
            id="icon-selector"
            list="icon-list"
            v-model="model"
            :placeholder="placeholder"
            class="form-control"
            :class="{'border-danger': state=== false}"
            name="icon"
            type="hidden"
    />
    <div class="invalid-feedback" :class="{'d-block': state === false}">{{ error }}</div>
    <b-modal v-model="show" size="xl" hide-header hide-footer centered class="icons" scrollable>
        <template v-for="(icon, index) in icons" >
            <template v-if="icon.attributes.membership.free.length">
                <i
                        data-bs-dismiss="modal"
                        v-for="(type, key) in icon.attributes.membership.free"
                        :title="icon.id"
                        :key="`${index}_${key}`"
                        :class="iconClass(icon.id, type)"
                        @click="select(iconClass(icon.id, type)), $emit('cancel')"
                ></i>
            </template>
        </template>
    </b-modal>
</template>

<style lang="scss" scoped>
    .icons {
        i {
          display: inline-block;
          text-align: center;
          cursor: pointer;
          width: 50px;
          height: 50px;
          line-height: 46px;
          font-size: 24px;
          margin: 0 10px 16px;
          border: 2px solid #eff0f2;
          color: #7F838B;
          fill: #7F838B;
          border-radius: 1rem;
          transition: all 0.4s;
          &:hover {
            background-color: rgba(0,0,0, 0.05);
          }
        }
    }

    .p-custom {
      padding: 0.47rem 0.75rem;
    }
</style>
